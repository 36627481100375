body {
  max-width: 1700px;
}

nav {
  margin-bottom: 20px;
}

li {
  margin-bottom: 10px;
}
